import React from 'react'
import { graphql, Link } from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
// import SEO from '../components/seo'
import Layout from '../containers/layout'
// import PortableText from '../components/portableText'
import CategoryList from '../components/CategoryList'
import TitleAndDescription from '../components/TitleAndDescription'
// import '../styles/content.css'

// import BlogPostPreview from '../components/blog-post-preview'
// import Image from '../../functions/gen-opengraph-image/src/image'

export const query = graphql`
  query IndexPageQuery {
    home: sanityHome {
    id
    name
    _rawExcerpt(resolveReferences: {maxDepth: 10})
    featured {
      title
      _rawExcerpt(resolveReferences: {maxDepth: 10})
      slug {
        current
      }
      publishedAt
      id
    }
  }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  // const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   )
  // }

  return (
    <Layout>
      {/* <SEO title={site.title} description={site.description} keywords={site.keywords} /> */}
      <Container>
        {/* <Image /> */}
        <TitleAndDescription title={data.home.name} excerpt={data.home._rawExcerpt} />
        {/* <h1 hidden>Welcome to {site.title}</h1>
        <h3 className='mt-4 mb-5 text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:leading-9"'>Featured</h3>
        <div className='markdown mb-8'>
          <PortableText blocks={data.home._rawExcerpt} />
        </div> */}
        {/* {postNodes && ( */}
        <BlogPostPreviewList featuredList title='' nodes={data.home.featured} browseMoreHref='/archive/' />
        <CategoryList />
        <Link to='/blog/1' className='relative inline-flex items-center mt-6 px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'>
          Daha fazla
        </Link>
        {/* )} */}

      </Container>
    </Layout>
  )
}

export default IndexPage
