import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BadgeList from './BadgeList'
import { mapEdgesToNodes } from '../lib/helpers'

const CategoryList = () => {
  const { data } = useStaticQuery(
    graphql`
      query MyQuery {
        data: allSanityCategory {
          edges {
            node {
              title
                slug {
                  current
              }
            }
          }
        }
      }
    `
  )
  const newCategories = mapEdgesToNodes(data)
  return <BadgeList categories={newCategories} />
}
export default CategoryList
