import { format } from 'date-fns'
import { Link } from 'gatsby'
import React from 'react'
import { getBlogUrl } from '../lib/helpers'
import PortableText from './portableText'

function BlogPostPreview (props) {
  return (
    <div className='mb-10'>
      <p className='text-sm leading-5 text-gray-500'>
        <time dateTime='2020-03-16'>{format(props.publishedAt, 'MMMM Do, YYYY')}</time>
      </p>
      <Link to={getBlogUrl(props.publishedAt, props.slug.current)} className='block'>
        <h3 className='mt-0 text-xl leading-7 font-semibold text-gray-900 hover:text-gray-700'>
          {props.title}
        </h3>
        {/* {props.featuredList && ( */}
        <div className='mt-1 text-base leading-6 text-gray-700'>
          <PortableText blocks={props._rawExcerpt} />
        </div>

        {/* )} */}
      </Link>
      {/* <div className='mt-2'>
        <Link
          to={getBlogUrl(props.publishedAt, props.slug.current)}
          className='text-sm leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150'
        >
          Read this article →
        </Link>
      </div> */}
    </div>
  )
}

export default BlogPostPreview
